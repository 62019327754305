<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-select
              :name="beFormReport.format.label"
              vid="format"
              :label="beFormReport.format.label"
              v-model="form.format"
              :options="allowedFormatOptions"
              :rules="{ required: true }"
            />
            <base-select
              :vid="beFormReport.request_input.relazioni_combo.label"
              :name="beFormReport.request_input.relazioni_combo.label"
              :label="beFormReport.request_input.relazioni_combo.label"
              v-model="form.request_input.relazioni_combo"
              :options="
                convertArrayToObject(
                  beFormReport.request_input.relazioni_combo.options
                )
              "
              :rules="{ required: true }"
            />
            <base-select
              vid="compagnie"
              name="compagnie"
              label="Compagnia"
              v-model="form.request_input.compagnie"
              :taggable="true"
              @select="onChangeInsurer()"
              :options="getInsurers()"
              @remove="onChangeInsurer()"
              :multiple="true"
              :required="true"
            />
            <base-select
              vid="ramo"
              name="ramo"
              label="Ramo"
              :taggable="true"
              v-model="form.request_input.rami"
              :options="risk_branches"
              :multiple="true"
              @select="onSelectBranch()"
              @remove="onRemoveBranch()"
              :closeOnSelect="false"
            />
            <base-select
              vid="prodotto"
              name="prodotto"
              label="Prodotto"
              v-model="form.request_input.prodotti"
              :options="products"
              :disabled="!canSelectProducts"
              :multiple="true"
              :closeOnSelect="false"
            />
            <base-select
              :vid="beFormReport.request_input.tipo_richiesta.label"
              :name="beFormReport.request_input.tipo_richiesta.label"
              :label="beFormReport.request_input.tipo_richiesta.label"
              v-model="form.request_input.tipo_richiesta"
              :options="
                convertArrayToObject(
                  beFormReport.request_input.tipo_richiesta.options
                )
              "
              :rules="{ required: true }"
            />
          </b-card-text>
          <b-button
            type="submit"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseSelect from "@/components/form/BaseSelect";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { mapGetters } from "vuex";
import moment from "moment";
import common from "@/components/reports/common.vue";
moment.locale("it");

export default {
  extends: common,
  data() {
    return {
      form: {
        report_id: null,
        format: "pdf",
        request_input: {
          relazioni_combo: null,
          tipo_richiesta: null,
          compagnie: [],
          rami: [],
          prodotti: []
        },
      },
      insurers: [],
      risk_branches: [],
      products: [],
      canSelectBranch: false,
      canSelectProducts: false,
    };
  },
  mixins: [StorageGetterMixin],
  components: {
    BaseSelect,
  },
  methods: {
    // override default
    createReport() {
      this.$emit("saveReport", this.form);
    },
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getProducts: "products",
      getBranches: "branches",
    }),
    onChangeInsurer() {
      this.risk_branches = this.getBranches()(this.form.request_input.compagnie);
      this.canSelectBranch = true;
      if(!this.form.request_input.compagnie.length == 1){
        this.form.request_input.prodotti = [];
        this.products = [];
        this.canSelectProducts = false;
      } 
    },
    onSelectBranch() {
      if(this.form.request_input.compagnie.length == 1){
        this.products = this.getProducts()(
          this.form.request_input.compagnie[0],
          this.form.request_input.rami,
          1
        );
        this.canSelectProducts = true;
      }
    },
    onRemoveBranch() {
      if(this.form.request_input.rami.length == 0){
        this.canSelectProducts = false;
      } else {
        this.products = this.getProducts()(
          this.form.request_input.compagnie[0],
          this.form.request_input.rami,
          1
        );
      }
    }
  },
  created() {
    /* */
    console.log("created REPONUCL");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("reports"))
      resources.push(
        this.$store
          .dispatch(`${path}reports`)
          .then(() => {
            console.info(`background data "${path}reports" fetched correctly`);
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("cooperators"))
      resources.push(
        this.$store
          .dispatch(`${path}cooperators`)
          .then(() => {
            console.info(
              `background data "${path}cooperators" fetched correctly`
            );
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log("All promises went through correctly");
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /* */
  },
  computed: {
    reportId() {
      return this.$store.state.auth.reports.find(
        (e) => e.code === this.reportCode
      ).id;
    },
  },
  mounted() {
    this.form.report_id = this.reportId;
    this.risk_branches = this.getBranches()();
  },
};
</script>
