<template>
  <div class="mx-1">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <b-row>
              <div class="form-group col-md-6">
                <div>
                  <b-button-toolbar>
                    <b-button-group>
                      <base-input
                          class="mr-2"
                          label="Clienti"
                          name="Anagrafica"
                          v-model="inpt_label_registry"
                          :rules="{ required: true }"
                          :readonly="true"
                      />
                    </b-button-group>
                    <b-button-group>
                      <b-button
                          class="mt-4 mb-5"
                          size="sm"
                          text="Button"
                          variant="lisaweb"
                          title="Ricerca Veloce"
                          @click="openQuickSearchRegistry"
                      >
                        <b-icon icon="search"></b-icon>
                      </b-button>
                    </b-button-group>
                    <b-button-group>
                      <b-button
                          class="mt-4 ml-2 mb-5"
                          size="sm"
                          text="Button"
                          variant="lisaweb"
                          @click="resetRegistryId"
                          title="Elimina Anagrafica"
                      >
                        <b-icon icon="trash"></b-icon>
                      </b-button>
                    </b-button-group>
                  </b-button-toolbar>
                </div>
              </div>
              <quick-search-registry-modal
                  ref="quickSearchRegistry"
                  @input="handleRegistryModalInput"
              >
              </quick-search-registry-modal>
              <div class="form-group col-md-6">
                <span
                    v-if="registry_data"
                    class="info"
                    v-html="toInfoData(registry_data, 'registry')"
                >
                </span>
              </div>
            </b-row>
            <base-select
                v-if="form.request_input.clienti"
                name="report_type"
                vid="report_type"
                label="Tipo Report"
                v-model="report_type"
                @input="onInputReportType"
                :options="[
                { text: 'Cliente selezionato', value: 'Cliente' },
                { text: 'Gruppi', value: 'gruppi' },
                { text: 'Relazioni', value: 'clienti_relazioni' },
              ]"
            />
            <b-card
                header="Gruppi"
                v-if="report_type === 'gruppi'"
                class="mt-2 mb-2"
            >
              <b-card-text>
                <base-select
                    vid="gruppi"
                    name="gruppi"
                    label="Gruppo"
                    v-model="form.request_input.gruppi"
                    @input="onInputRegistryGroup"
                    :options="registryGroupOptions"
                    :rules="{ required: true }"
                />
              </b-card-text>
            </b-card>
            <b-card
                header="clienti_relazioni"
                class="mt-2 mb-2"
                v-if="report_type === 'clienti_relazioni'"
            >
              <b-card-text>
                <base-select
                    vid="relazioni"
                    name="relazioni"
                    label="Relazioni"
                    v-model="form.request_input.clienti_relazioni"
                    :multiple="true"
                    :taggable="true"
                    :closeOnSelect="false"
                    :options="relationOptions"
                    :rules="{ required: true }"
                />
              </b-card-text>
            </b-card>
            <base-select
                vid="formato_aggrecli"
                name="formato_aggrecli"
                label="Versione Scheda"
                v-model="form.request_input.formato_aggrecli"
                :options="
                convertArrayToObject(
                  beFormReport.request_input.formato_aggrecli.options
                )
              "
                :rules="{ required: true }"
            />
            <base-select
                :name="beFormReport.format.label"
                vid="format"
                :label="beFormReport.format.label"
                v-model="form.format"
                :rules="{ required: true }"
                :options="allowedFormatOptions"
            />
          </b-card-text>
          <b-button
              type="submit"
              :disabled="invalid"
              variant="outline-lisaweb"
              size="sm"
              class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import {toInfoData /* , toRelationString */} from "@/utils/transforms";
import common from "@/components/reports/common.vue";

export default {
  extends: common,
  data() {
    return {
      isLoadingAjax: false,
      registryGroupOptions: [],
      relationOptions: [],
      report_type: null,
      registry_data: null,
      inpt_label_registry: "",
      form: {
        format: null,
        report_id: this.reportId,
        request_input: {
          clienti: [],
          clienti_relazioni: [],
          gruppi: [],
          formato_aggrecli: null,
        },
      },
    };
  },
  components: {
    BaseInput,
    BaseSelect,
    QuickSearchRegistryModal,
  },
  methods: {
    toInfoData,
    onInputRegistryGroup(id) {
      if (id) {
        this.form.request_input.gruppi = [id];
      }
    },
    onInputRegistry(value) {
      const registry = value;
      if (registry) {
        // Registry Groups
        if (registry.registry_groups.length) {
          this.registryGroupOptions = registry.registry_groups.map(
              (registry_group) => {
                return {
                  value: registry_group.id,
                  text: `${registry_group.code}-${registry_group.title}`,
                };
              }
          );
        } else {
          this.form.request_input.gruppi = [];
          this.registryGroupOptions = [];
        }
        // Relative Types
        if (registry.registries.length) {
          this.relationOptions = registry.registries.map((registry) => {
            return {
              value: registry.id,
              text:
                  registry.status_registry.value === 1
                      ? `${registry.attributables.CREG}-${registry.pivot.relative_type.male}`
                      : registry.attributables.SEX === "M"
                          ? `${registry.attributables.SURN} ${registry.attributables.NAME} - ${registry.pivot.relative_type.male}`
                          : `${registry.attributables.SURN} ${registry.attributables.NAME} - ${registry.pivot.relative_type.male}`,
            };
          });
        } else {
          this.form.request_input.clienti_relazioni = [];
          this.relationOptions = [];
        }
      }
    },
    onDeleteRegistry() {
      this.report_type = null;
      this.form.request_input.gruppi = [];
      this.form.request_input.clienti_relazioni = [];
      this.registryGroupOptions = [];
      this.relationOptions = [];
    },
    onInputReportType(val) {
      if (val === "clienti_relazioni") {
        this.form.request_input.gruppi = [];
      }
      if (val === "gruppi") {
        this.form.request_input.clienti_relazioni = [];
      }
      if (val === "Cliente") {
        this.form.request_input.clienti_relazioni = [];
        this.form.request_input.gruppi = [];
      }
      console.log(val);
      if (!val) {
        this.form.request_input.clienti_relazioni = [];
        this.form.request_input.gruppi = [];
      }
    },
    openQuickSearchRegistry() {
      this.$bvModal.show("quickSearchRegistryModal");
    },
    handleRegistryModalInput(value) {
      this.onInputRegistry(value);
      this.form.request_input.clienti = [value.id];
      this.registry_data = value;
      this.inpt_label_registry = this.toInfoData(
          this.registry_data,
          "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    resetRegistryId() {
      this.onDeleteRegistry();
      this.registry_data = null;
      this.inpt_label_registry = null;
      this.form.request_input.clienti = [];
    },
  },
};
</script>
