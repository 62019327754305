<template>
  <div class="mx-1" v-if="formLoaded">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.prevent="handleSubmit(createReport)">
        <b-card>
          <b-card-text>
            <base-select
                name="status"
                label="STATO"
                :options="beCreateForm['task'].status_task.options"
                v-model="form.request_input.status"
                :rules="{ required: true }"
                :multiple="true"
            />
            <base-datepicker
                vid="data_da"
                name="Data scadenza da"
                label="Data scadenza da"
                v-model="form.request_input.data_da"
            />
            <base-datepicker
                vid="data_a"
                name="Data scadenza a"
                label="Data scadenza a"
                v-model="form.request_input.data_a"
            />
          </b-card-text>
          <b-button
              type="submit"
              :disabled="invalid"
              variant="outline-lisaweb"
              size="sm"
              class="float-right"
          >
            Salva
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseSelect from "@/components/form/BaseSelect";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import moment from "moment";
import CalendarEventsAndTasksMixin from "@/mixins/CalendarEventsAndTasksMixin";

moment.locale("it");
import common from "@/components/reports/common.vue";

export default {
  extends: common,
  data() {
    return {
      formLoaded: false,
      repository: 'task',
      beCreateForm: [],
      form: {
        report_id: this.reportId,
        format: "pdf",
        request_input: {
          data_a: null,
          data_da: null,
          status: [],
        },
      },
    };
  },

  mixins: [
    StorageGetterMixin,
    CalendarEventsAndTasksMixin
  ],
  components: {
    BaseDatepicker,
    BaseSelect,
  },
  methods: {
    createReport() {
      this.$emit("saveReport", this.form);
    },
  },
  created() {
    /* */
    console.log("created REPATT");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("reports"))
      resources.push(
          this.$store
              .dispatch(`${path}reports`)
              .then(() => {
                console.info(`background data "${path}reports" fetched correctly`);
              })
              .catch((error) => {
                let errMsg = this.$getErrorMessage(error);
                this.$showSnackbar({preset: "error", text: errMsg});
              })
      );
    if (resources.length) {
      this.getResources(resources)
          .then(() => {
            console.log("All promises went through correctly");
          })
          .catch((error) => {
            console.log("One or more promises crashed... :|");
            this.onFailure(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
    } else {
      this.isLoading = false;
    }
    /* */
  },
  beforeMount() {
    this.fetchCreateForm(this.repository)
        .then(() => {
          this.beCreateForm[this.repository] =
              this.convertDataToCalendarEvents([this.beForm[this.repository]])[0];
          this.formLoaded = true;
        })
        .catch((error) => {
          console.error(error);
        });
  },
  // computed: {
  //   reportId() {
  //     return this.$store.state.auth.reports.find(
  //       (e) => e.code === this.reportCode
  //     ).id;
  //   },
  // },
  // mounted() {
  // debugger;
  // this.form.report_id = this.reportId;
  // },
};
</script>
