<template>
  <div class="mt-0">
    <b-row v-if="hasOptions">
      <b-col align="right">
        <b-dropdown right size="sm" variant="transparent">
          <template #button-content>
            <b-icon icon="gear"></b-icon>
            Opzioni
          </template>
          <b-dropdown-item v-if="!hasPermissions"
            >Nessun report disponibile</b-dropdown-item
          >
          <div v-else>
            <b-dropdown-group
              id="dropdown-group-1"
              header="Esporta CSV"
              v-if="options.filter((e) => e.formats.includes('csv'))"
            >
              <span
                v-for="(option, index) in options.filter((e) =>
                  e.formats.includes('csv')
                )"
                :key="index"
              >
                <b-dropdown-item-button
                  v-if="hasType('csv', option) && canReport('csv', option)"
                >
                  <b-dropdown-item
                    v-if="hasType('csv', option) && canReport('csv', option)"
                    @click="handle('csv', option)"
                    >{{ getLabel("csv", option) }}</b-dropdown-item
                  >
                </b-dropdown-item-button>
              </span>
            </b-dropdown-group>
            <b-dropdown-group
              id="dropdown-group-2"
              header="Esporta PDF"
              v-if="options.filter((e) => e.formats.includes('pdf')).length"
            >
              <span
                v-for="(option, index) in options.filter((e) =>
                  e.formats.includes('pdf')
                )"
                :key="index"
              >
                <b-dropdown-item-button
                  v-if="hasType('pdf', option) && canReport('pdf', option)"
                >
                  <b-dropdown-item
                    v-if="hasType('pdf', option) && canReport('pdf', option)"
                    @click="handle('pdf', option)"
                    >{{ getLabel("pdf", option) }}</b-dropdown-item
                  >
                </b-dropdown-item-button>
              </span>
            </b-dropdown-group>
          </div>
        </b-dropdown>
      </b-col>
    </b-row>
    <submit-report-modal
      :code="realCode(optCod)"
      @save-report="saveReport"
      :beFormCreate="beFormCreate"
      :beRulesCreate="beRulesCreate"
      :allowedFormatOptions="allwdOpts"
    />
    <select-option-modal
      :opts="[
        { text: 'Si', value: 'Y' },
        { text: 'No', value: 'N' },
      ]"
      title="Stampa percentuali applicate?"
      @select="onSelectOption"
    />
  </div>
</template>

<script>
import ExportsMixin from "@/mixins/ExportsMixin";
import ReportsMixin from "@/mixins/ReportsMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import SubmitReportModal from "@/components/modals/submitReportModal";
import SelectOptionModal from "@/components/modals/selectOption";
import { canVerb } from "@/utils/permissions";
import { mapGetters } from "vuex";

export default {
  mixins: [ExportsMixin, ReportsMixin, StorageGetterMixin],
  data() {
    return {
      // UNUSEFUL variable expomons: EXPO* reports permissions DO NOT depend on mapped index permission, but as usual on pivot.allowed flags!!!
      // however, this is the mapping between report-code and model, if need to discover the index where an EXPO* report would preferably be placed...
      // expomons: {
      //   EXPOADBO: "address_books",
      //   EXPOAGBO: "agency_books",
      //   EXPOAGCO: "agency_contacts",
      //   EXPOAGSE: "agency_setups",
      //   EXPOATTR: "attributes",
      //   EXPOBOEN: "book_entries",
      //   EXPOBREA: "breakdowns",
      //   EXPOBRST: "broker_statements",
      //   EXPOBROK: "brokers",
      //   EXPOCLAI: "claims",
      //   EXPOCLPA: "claim_parties",
      //   EXPOCOTR: "consolidated_treasuries",
      //   EXPOCORR: "correspondences",
      //   EXPODOCU: "documents",
      //   EXPOGERE: "generated_reports",
      //   EXPOINAC: "insurer_accounting",
      //   EXPOINAN: "insurance_ancillaries",
      //   EXPOINPA: "insurance_payments",
      //   EXPOINPO: "insurance_policies",
      //   EXPOINRI: "insurance_risks",
      //   EXPOINSU: "insurers",
      //   EXPOINAR: "insurer_participants",
      //   EXPOMACO: "mandate_codes",
      //   EXPOPARE: "payment_references",
      //   EXPOREGI: "registries",
      //   EXPOREGR: "registry_groups",
      //   EXPOSECT: "sectors",
      //   EXPOTASK: "tasks",
      //   EXPOTREA: "treasuries",
      //   EXPOUNPA: "unsolved_payments",
      //   EXPOUSER: "users",
      //   EXPOVAAC: "various_accounting",
      // },
      exportFormat: null, // used for custom reports (eg: RENPROVUNOFFICIALIZED) that open custom modal with custom extras
      extraOption: null,
      optionCode: null,
      allowed: [], // allowed report formats for the chosen report (for compatibility with generated reports, since here we already know the format of the chosen report)
    };
  },
  created() {
    /* */
    console.log("created index");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("reports")) {
      resources.push(
        this.$store
          .dispatch(`${path}reports`)
          .then(() => {
            console.info(`background data "${path}reports" fetched correctly`);
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    }
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log("All promises went through correctly");
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /* */
  },
  props: {
    exportUrl: String, // value is defined by onSearch methods
    repository: String, // value is defined at index
    resource: String, // value is defined at index
    tableRef: null, // value is defined at index
    filter: {
      // valore definito nelle index
      type: Object,
      default() {
        return {};
      },
    },
    options: {
      type: Array,
      default() {
        return [
          {
            code: null,
            label: null,
            formats: [],
            // utilizzare extra SOLO casi particolari, es: 'RENPROVUNOFFICIALIZED'
            // quando queryString deve inviare dati specifici... (dipende dal report)
            // extra: {
            //   produttori: smids,
            //   salesman_id: smid,
            // },
            extra: {},
          },
        ];
      },
    },
  },
  components: {
    SubmitReportModal,
    SelectOptionModal,
  },
  methods: {
    canVerb,
    getLabel(type, option) {
      if (option.label) {
        return `${option.label}`;
      }
      let rc = this.realCode(option.code);
      let r = this.getReport()(rc);
      // return `Esporta ${type}: ${r?.title}`;
      return `${r.title}`;
    },
    canReport(type, option) {
      let c = option.code;
      if (!c) {
        return false;
      }
      // report RENPROVUNOFFICIALIZED è "fittizio" -> segue i permessi di RENPROV
      // report ESTRCNT è "fittizio" -> segue i permessi di EXPOSECT

      let rc = this.realCode(c);
      console.log("RC: ", rc);
      // WRONG: EXPO* reports permissions depend on mapped index (expomons), not pivot.allowed flags??? NO!
      // if (rc.startsWith("EXPO")) {
      //   return this.canVerb(this.expomons[rc], "index");
      // }
      let r = this.getReport()(rc);
      if (!r || !r.users || !r?.users?.length || !r?.users[0]?.pivot) {
        try {
          console.debug(
            `canReport: NO -> report: ${JSON.stringify(
              Object.keys(r)
                .filter((e) => ["id", "code", "title", "users"].includes(e))
                .reduce((obj, key) => {
                  return Object.assign(obj, {
                    [key]: r[key],
                  });
                }, {}),
              null,
              2
            )}`
          );
        } catch (error) {
          console.error(error);
        }
        return false;
      }
      return r.users[0]?.pivot.allowed?.includes(type);
    },
    realCode(code) {
      // return the base code of a variant report
      // NOTE: keep this up-to-date when new variant reports are introduced
      let c;
      switch (code) {
        case "RENPROVUNOFFICIALIZED":
          c = "RENPROV";
          break;
        case "ESTRCNT":
          c = "EXPOSECT";
          break;
        default:
          c = code;
          break;
      }
      return c;
    },
    handle(type, option) {
      // based on report type and option's infos, trigger the correct method of ExportsMixin/ReportsMixin
      // NOTE: keep this up-to-date when new reports are introduced
      let c = option.code;
      this.$set(this, "optionCode", c);
      this.$set(this, "exportFormat", type);
      this.$set(this, "extraOption", option.extra);
      /**
       * ci sono 2 modalità di esportazione (handler):
       * 1. ExportsMixin: onExport(format, reportCode = "EXPOMON", requestInput = {}, silent = false)
       * 2. ReortsMixin: saveReport(code, form)
       *
       * ESEMPIO
       * INCACOMP2 viene esportato in csv e pdf
       * @csv="onExport('csv')"
       * @pdf="openReportModal('INCACOMP2')"
       *
       */
      switch (type) {
        case "csv":
          switch (c) {
            case "RENPROVUNOFFICIALIZED":
            case "TOTASOSP":
            case "REGIFOCA":
              this.$nextTick(() => {
                this.openReportModal(c);
              });
              break;
            default:
              this.onExport(type, c);
              break;
          }
          break;
        case "pdf":
          switch (c) {
            case "RENPROVUNOFFICIALIZED":
            case "RENCOLLAB":
              // case "TOTASOSP":
              this.$nextTick(() => {
                this.openReportModal(c);
              });
              break;
            case "FOCACOL":
            case "LISTSOSP":
            case "EXPOBOEN":
            case "EXPOINAN":
            case "EXPOINPO":
            case "EXPOREGI":
            case "EXPOCLAI":
            case "ESTCONAG":
            case "TOTAFOCA":
            case "ESTCONCO":
            case "EXPOVAAC":
            case "LISTABB":
            case "CONREGCOL":
            case "EXPOREGR":
            case "EXPOAGCO":
            case "EXPOUNPA":
            case "LISTACC":
            case "EXPOAGBO":
            case "RENCOMPROV":
            case "EXPOSECT":
            case "EXPOPARE":
            case "EXCOGISO":
            case "EXCOGIAC":
            case "EXCOGICO":
            case "EXCOGIMO":
            case "EXPOINPA":
              this.onExport(type, c);
              break;
            // case "TOTASOSP": // REVIEW: ??? nel .docx scrivono 'report applicato ai risultati di una precedente ricerca', MA IN PDF???
            //   this.onExport(type, c);
            //   break;
            default:
              this.$nextTick(() => {
                this.openReportModal(c);
              });
              break;
          }
          break;
        default:
          break;
      }
    },
    openReportModal(code) {
      switch (code) {
        case "RENPROVUNOFFICIALIZED":
          this.$bvModal.show("selectOption");
          break;
        case "RENCOLLAB":
        case "TOTASOSP":
        case "INCACOL":
        case "REPONUCL":
          // questi report richiedono beForm
          this.fetchForm(code)
            .then(() => {
              this.$bvModal.show(code);
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: errMsg,
              });
            });
          break;
        default:
          this.$bvModal.show(code);
          break;
      }
    },
    onSelectOption(value) {
      switch (this.optCod) {
        case "RENPROVUNOFFICIALIZED":
          if (value != null) {
            let payload = this.xtrOpt;
            payload["show_percentages"] = value;
            this.onExport(this.exportFormat, this.optCod, payload);
          }
          break;
        case "RENCOLLAB":
          if (value != null) {
            let payload = {};
            payload["show_percentages"] = value;
            this.onExport(this.exportFormat, this.optCod, payload);
          }
          break;
        case "TOTASOSP":
          this.onExport(this.exportFormat, this.optCod);
          break;
        default:
          break;
      }
    },
    fetchForm(code) {
      return this.fetchEditForm("report", code)
        .then(() => {
          this.$set(this, "beFormCreate", this.beForm["report"]);
          this.$set(this, "beRulesCreate", this.beRules["report"]);
          // ho già selezionato sia il report sia il format (exportFormat)
          // a differenza di generated-reports, dove la modale chiede all'utente di scegliere un formato,
          // qui il formato risulta già scelto, quindi lo imposto direttamente
          // sarà poi il report stesso a validarne il permesso
          // e soprattutto ad impostare form.format nel created hook, quando come adesso tra i formati ne arriva solo 1
          this.$set(
            this,
            "allowed",
            this.beForm["report"].format.options.filter(
              (e) => e.text === this.exportFormat
            )
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
    ...mapGetters("auth", {
      getReport: "report",
    }),
  },
  computed: {
    optCod() {
      return this.optionCode;
    },
    xtrOpt() {
      return this.extraOption;
    },
    allwdOpts() {
      return this.allowed;
    },
    hasOptions() {
      return this.options.length ? true : false;
    },
    hasType() {
      return (type, option) => {
        return option.formats.includes(type);
      };
    },
    hasPermission() {
      return (option) => {
        if (!this.hasType("pdf", option) && !this.hasType("csv", option)) {
          return false;
        } else if (this.hasType("pdf", option) || this.hasType("csv", option)) {
          if (
            !this.canReport("pdf", option) &&
            !this.canReport("csv", option)
          ) {
            return false;
          }
        }
        return true;
      };
    },
    hasPermissions() {
      let ret = false;
      for (const option of this.options) {
        // if (!this.hasType("pdf", option) && !this.hasType("csv", option)) {
        //   ret &&= false;
        // } else if (this.hasType("pdf", option) || this.hasType("csv", option)) {
        //   if (
        //     !this.canReport("pdf", option) &&
        //     !this.canReport("csv", option)
        //   ) {
        //     ret &&= false;
        //   }
        // }
        ret ||= this.hasPermission(option);
      }
      return ret;
    },
    allReportCodes() {
      // return [
      //   'AGGRECLI',   'AGRENPROV', 'COMINCA',    'CONREGCOL',
      //   'EXPOADBO',   'EXPOAGBO',  'EXPOAGCO',   'EXPOAGSE',
      //   'EXPOATTR',   'EXPOBOEN',  'EXPOBREA',   'EXPOBROK',
      //   'EXPOBRST',   'EXPOCLAI',  'EXPOCLPA',   'EXPOCORR',
      //   'EXPOCOTR',   'EXPODOCU',  'EXPOGERE',   'EXPOINAC',
      //   'EXPOINAN',   'EXPOINAR',  'EXPOINPA',   'EXPOINPO',
      //   'EXPOINRI',   'EXPOINSU',  'EXPOMACO',   'EXPOMON',
      //   'EXPOPARE',   'EXPOREGI',  'EXPOREGR',   'EXPOSECT',
      //   'EXPOTASK',   'EXPOTREA',  'EXPOUNPA',   'EXPOUSER',
      //   'EXPOVAAC',   'FOCACOL',   'INCACLIGRU', 'INCACOL',
      //   'INCACOMP',   'INCACOMP2', 'INCAPAGA',   'INCAPROD',
      //   'INCARAM',    'INCARAMTI', 'LISPOL',     'LISTIT',
      //   'LISTITINCA', 'MONUPROD',  'POLCLIGRU',  'POLCLIREL',
      //   'PORTATTI',   'REGIFOCA',  'RENCOLLAB',  'RENCOMP',
      //   'RENCOMPROV', 'RENPROD',   'RENPROV',    'RUBRICLI',
      //   'RUBRIGRU',   'SCACLIGRU', 'STATSCONT',  'TITINCACLI',
      //   'TITULTIGI',  'TOTAFOCA',  'TOTASOSP', LISTABB
      // ]
      return this.$store.state.auth.reports.map((e) => e.code).sort();
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.dropdown-item) {
  font-size: 0.75rem;
  padding: 0.25rem 0.75rem;
}
:deep(.dropdown-menu.show) {
  min-width: fit-content;
}
:deep(.btn-group > .btn) {
  font-size: 0.75rem;
}
:deep(.dropdown-header) {
  font-size: 0.75rem;
  padding: 0.25rem 1.1rem;
}
</style>
